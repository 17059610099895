import styled from 'styled-components';

import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const AccordionSearchStyles = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 8px;
  color: ${colors.gray[900]};

  ${atMinWidth.md`
    align-items: flex-start
  `}
`;

export const AccordionSearchHeading = styled.p`
  ${font('display', 'md', '700')};

  ${atMinWidth.md`
    ${font('display', 'lg', '700')}
  `}
`;

export const AccordionSearchText = styled.p`
  ${font('text', 'lg', '400')};
  margin-bottom: 16px;

  ${atMinWidth.md`
    ${font('text', 'xl', '400')}
  `}
`;
