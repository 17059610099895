import React, { useContext } from 'react';

import CallToAction from '../../../molecules/CallToAction/CallToAction';

import { FaqSearchContext } from '../../../contexts/faqSearchContext';

import {
  AccordionSearchHeading,
  AccordionSearchStyles,
  AccordionSearchText,
} from './AccordionSearch.styles';

const AccordionSearch = ({ search }) => {
  const { faqSearchState, faqSearchDispatch } = useContext(FaqSearchContext);

  const handleClick = () => {
    faqSearchDispatch({ type: 'SET_SEARCH', payload: '' });
  };

  return (
    <AccordionSearchStyles>
      <AccordionSearchHeading>Search Results</AccordionSearchHeading>
      <AccordionSearchText>
        Displaying {faqSearchState.count} {search && `for "${search}"`}
      </AccordionSearchText>
      <CallToAction
        value="Back"
        icon="left"
        customHandleClick={handleClick}
        variant="tertiary"
        reverse
      />
    </AccordionSearchStyles>
  );
};

export default AccordionSearch;
